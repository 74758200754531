<template>
  <v-expansion-panel v-if="loggedRecruiter">
    <expansion-panel-header
      :interview="interviews"
      :interviewId="interviewId"
    />
    <v-expansion-panel-content class="mt-12">
      <v-row class="d-flex justify-space-between align-center px-5">
        <!-- <v-col cols="12" md="8" xl="9"> -->
        <div>
          <div v-if="isGroup">
            <v-chip-group
              v-model="selectedCandidate"
              v-if="isGroup"
              active-class="primary text--white"
              mandatory
              column
              hide-details
            >
              <template v-for="candidate in interviews">
                <v-chip
                  :key="
                    candidate.interviewee
                      ? candidate.interviewee.id
                      : candidate.email
                  "
                  :value="candidate"
                  class="ma-2"
                  label
                  :disabled="candidate.blocked"
                  :close="!candidate.interviewee && isInterviewer"
                  close-icon="mdi-pencil"
                  hide-details
                  @click:close="editEmail(candidate.email)"
                >
                  <v-avatar left>
                    <v-img
                      :src="
                        candidate.interviewee && candidate.interviewee.photo
                          ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                          : '/avatar.png'
                      "
                    ></v-img> </v-avatar
                  >{{
                    candidate.interviewee
                      ? splitName(candidate.interviewee.fullName)
                      : candidate.email
                  }}
                </v-chip>
              </template>
            </v-chip-group>
          </div>
        </div>
        <div>
          <div class="d-flex flex-row">
            <v-btn
              v-if="isInterviewer"
              text
              color="primary"
              :disabled="isRunning"
              @click="openOptionsReSchedule()"
            >
              <v-icon>mdi-calendar</v-icon>
              <span>{{ $t("schedule_interview") }}</span>
            </v-btn>
            <v-btn
              v-if="isInterviewer || (isObserver && isStageRunning)"
              :loading="isLoading2"
              color="primary"
              outlined
              @click="updateState(buttonActions.value)"
            >
              <span>{{ $t(buttonActions.text) }}</span>
              <v-icon>{{ buttonActions.icon }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-row>

      <v-row v-if="selectedCandidate.interviewee" class="px-5">
        <v-btn
          text
          small
          color="primary"
          target="_blank"
          :to="`/pdf/${selectedCandidate.interviewee.id}`"
        >
          <v-icon>mdi-file-account-outline</v-icon>
          <span>{{ $t("view_cv") }}</span>
        </v-btn>
      </v-row>

      <v-row class="px-5">
        <v-col cols="12" md="8" xl="9">
          <classification
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isObserver="isObserver"
            class="mb-10"
          />

          <comments
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            class="mb-12"
          />
        </v-col>

        <v-col cols="12" md="4" xl="3" class="mr-0 pr-0">
          <results
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isRunningButNotJoined="isRunningButNotJoined"
            @chageStage="chageStage"
            @updatedState="isLoading2 = false"
          />
          <recruter-list
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            class="mb-10"
            @select="selectRecruter"
          />
        </v-col>

        <v-col cols="12" md="12">
          <div
            v-if="!isCompleted && isInterviewer"
            v-show="!isRunning"
            class="text-right mt-12 mb-10"
          >
            <v-btn
              text
              small
              color="primary"
              class="mt-12"
              @click="openCancelDialog()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mt-12"
              @click="openFinishDialog()"
            >
              {{ $t("finish") }} {{ $t("interview") }}
            </v-btn>
          </div>
        </v-col>

        <!-- <v-col cols="12" md="12" class="text-right px-10 py-10">
          <v-btn text small color="primary">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary">
            {{ $t("finish") }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-expansion-panel-content>

    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="selectedCandidate"
      :isRunning="isRunning"
      @close="noteDialog = false"
    />

    <progress-dialog :processing="isLoading" />
  </v-expansion-panel>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import { JOIN_OR_LEFT_INTERVIEWER_MUTATION } from "../../graphql/Mutation.gql";
import ExpansionPanelHeader from "./ExpansionPanelHeader.vue";
import Classification from "./Classification.vue";
import Results from "./Results.vue";
import RecruterList from "./RecruterList.vue";
import Comments from "./Comments.vue";
import Notes from "./Notes.vue";

export default {
  name: "ExpansionPanel",

  components: {
    ExpansionPanelHeader,
    Classification,
    Results,
    RecruterList,
    Comments,
    Notes,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    isCompleted: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    noteDialog: false,
    knowledge: 40,
    selectedCandidate: undefined,
    selectedRecruter: undefined,
    selectedStage: undefined,
    loggedRecruiter: null,
    joined: false,
    master: false,
    isLoading: false,
    isLoading2: false,
  }),

  watch: {
    interviews(updatedInterview) {
      // const FIRST_ELEMENT = 0;
      this.getCurrentSelected(updatedInterview);
      this.setCurrentSelectedRecruter(updatedInterview);
      this.setLoggedRecruiter(updatedInterview);

      const CURRENT_STAGE = this.getCurrentStage(this.selectedCandidate);
      this.selectedStage = CURRENT_STAGE.name;

      if (CURRENT_STAGE.state === "running") {
        clearInterval(this.cron);
      }
    },

    selectedCandidate(val) {
      const CURRENT_STAGE = this.getCurrentStage(val);

      const currentSelectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );

      if (currentSelectedRecruter) {
        this.selectedRecruter = currentSelectedRecruter;
        this.selectedRecruter.isObserver = false;
      } else {
        this.selectedRecruter = CURRENT_STAGE.observers.find(
          (findObserver) =>
            findObserver.user.id === this.selectedRecruter.user.id
        );
        this.selectedRecruter.isObserver = true;
      }
    },

    loggedRecruiter(val) {
      if (!val.joined) {
        // eslint-disable-next-line no-undef
        Fire.$emit("left");
      }
    },
  },

  created() {
    const FIRST_ELEMENT = 0;
    const CURRENT_STAGE = this.getCurrentStage(this.interviews[FIRST_ELEMENT]);

    this.selectedStage = CURRENT_STAGE.name;

    this.selectedCandidate = this.interviews[FIRST_ELEMENT];
    const logggedRecruter = CURRENT_STAGE.recruters.find(
      (findRecruter) => findRecruter.user.id === this.userId
    );

    if (logggedRecruter) {
      this.selectedRecruter = logggedRecruter;
      this.selectedRecruter.isObserver = false;
    } else if (CURRENT_STAGE.observers.length > 0) {
      this.selectedRecruter = CURRENT_STAGE.observers.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );
      this.selectedRecruter.isObserver = true;
    } else {
      this.selectedRecruter = CURRENT_STAGE.recruters[FIRST_ELEMENT];
    }

    this.setLoggedRecruiter(this.interviews);
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    userId() {
      return this.$root.$data.userId;
    },
    isGroup() {
      return this.interviews.length > 1;
    },
    buttonActions() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );
      if (currentStage.state === "running" && this.isMaster) {
        return { text: this.$t("pause"), icon: "mdi-pause", value: "pause" };
      } else if (
        currentStage.state === "running" &&
        !this.isMaster &&
        !this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      } else if (
        currentStage.state === "running" &&
        !this.isMaster &&
        this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("Goout"),
          icon: "mdi-logout",
          value: "left",
        };
      } else if (
        this.isObserver &&
        currentStage.state !== "running" &&
        !this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      }
      return { text: this.$t("start"), icon: "mdi-play", value: "running" };
    },

    isRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && this.loggedRecruiter.joined;
    },

    isStageRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running";
    },

    isRunningButNotJoined() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && !this.loggedRecruiter.joined;
    },

    isInterviewer() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.getCurrentStage(this.interviews[FIRST_ELEMENT]);

      const interviewer = currentStage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      return !!interviewer;
    },

    isObserver() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      const interviewer = currentStage.observers.find(
        (findObserver) => findObserver.user.id === this.userId
      );

      return !!interviewer;
    },

    isMaster() {
      return this.loggedRecruiter.isMaster;
    },
  },

  beforeDestroy() {
    clearInterval(this.cron);
  },

  methods: {
    editEmail(candidateEmail) {
      const params = {
        interviewId: this.interviewId,
        candidateEmail,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("EditEmailDialog", params);
    },

    getCurrentSelected(updatedInterview) {
      const FIRST_ELEMENT = 0;

      const selectedCandidateIndex = updatedInterview.findIndex(
        (findInterview) =>
          (findInterview.interviewee &&
            this.selectedCandidate.interviewee &&
            findInterview.interviewee.id ===
              this.selectedCandidate.interviewee.id) ||
          (!findInterview.interviewee &&
            !this.selectedCandidate.interviewee &&
            findInterview.email === this.selectedCandidate.email)
      );

      this.selectedCandidate =
        selectedCandidateIndex > -1
          ? updatedInterview[selectedCandidateIndex]
          : updatedInterview[FIRST_ELEMENT];
    },

    setCurrentSelectedRecruter(updatedInterview) {
      const FIRST_ELEMENT = 0;
      const CURRENT_STAGE = this.getCurrentStage(
        updatedInterview[FIRST_ELEMENT]
      );

      let oldSelectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );

      if (oldSelectedRecruter) {
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = false;
      } else {
        oldSelectedRecruter = CURRENT_STAGE.observers.find(
          (findObserver) =>
            findObserver.user.id === this.selectedRecruter.user.id
        );
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = true;
      }
    },

    setLoggedRecruiter(interview) {
      const FIRST_ELEMENT = 0;
      const CURRENT_STAGE = this.getCurrentStage(interview[FIRST_ELEMENT]);

      const logggedRecruiter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      if (logggedRecruiter) {
        const isMaster = CURRENT_STAGE.master === this.userId;
        this.loggedRecruiter = logggedRecruiter;
        this.loggedRecruiter.isMaster = isMaster;
      } else {
        this.loggedRecruiter = CURRENT_STAGE.observers.find(
          (findObserver) => findObserver.user.id === this.userId
        );
      }
    },

    chageStage(stage) {
      const FIRST_ELEMENT = 0;
      const logggedRecruter = stage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      this.selectedStage = stage.name;

      if (logggedRecruter) {
        this.selectedRecruter = logggedRecruter;
        this.selectedRecruter.isObserver = false;
      } else if (stage.observers.length > 0) {
        this.selectedRecruter = stage.observers.find(
          (findRecruter) => findRecruter.user.id === this.userId
        );
        this.selectedRecruter.isObserver = true;
      } else {
        this.selectedRecruter = stage.recruters[FIRST_ELEMENT];
      }
    },

    getCurrentStage(interview) {
      return interview.stages.find(
        (findStage) => findStage.name === interview.currentStage
      );
    },

    selectRecruter(recruter) {
      this.selectedRecruter = recruter;
    },

    updateState(state) {
      console.log("state: ", state);
      if (state === "join") {
        this.joinOrLeft(true);
      } else if (state === "left") {
        this.joinOrLeft(false);
      } else {
        this.isLoading2 = true;
        if (state === "running") {
          // eslint-disable-next-line no-undef
          Fire.$emit("startOrPauseInterviewInResultComponents", state);
          // eslint-disable-next-line no-undef
          Fire.$emit("joined");
        }

        if (state === "pause") {
          // eslint-disable-next-line no-undef
          Fire.$emit("startOrPauseInterviewInResultComponents", state);
          // eslint-disable-next-line no-undef
          Fire.$emit("left");
        }
      }
    },

    openOptionsReSchedule() {
      const params = {
        selectedCandidate: this.selectedCandidate,
        interviews: this.interviews,
        interviewId: this.interviewId,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("OptionsReSchedule", params);
    },

    openNotes() {
      this.noteDialog = true;
    },

    openFinishDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("FinishInterview", params);
    },

    openCancelDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("CancelInterview", params);
    },

    splitName(name) {
      return name.split(" ")[0];
    },

    async joinOrLeft(isJoin) {
      const FIRST_ELEMENT = 0;
      this.isLoading2 = true;
      const candidates = this.getCandidateIdsFromInterviews();
      const stage = this.interviews[FIRST_ELEMENT].currentStage;
      try {
        await this.$apollo.mutate({
          mutation: JOIN_OR_LEFT_INTERVIEWER_MUTATION,
          variables: {
            interviewUpdateStateInput: {
              interviewId: this.interviewId,
              candidates,
              state: "running",
              stage,
              isJoin,
            },
          },
        });
        if (isJoin) {
          // eslint-disable-next-line no-undef
          Fire.$emit("joined");
        } else {
          // eslint-disable-next-line no-undef
          Fire.$emit("left");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading2 = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },
  },
};
</script>

<style scoped>
.results {
  background-color: rgba(253, 240, 215, 0.7);
}
</style>
