<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="justify-center" style="margin-top: -45px">
      <v-col cols="12" md="8">
        <v-alert
          v-model="alert"
          color="primary_text"
          dense
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
          >{{ $t("alert_scheduled_interview") }}</v-alert
        >
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <div>
        <v-btn icon color="primary" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="d-flex flex-row justify-space-between align-center mb-10">
      <div>
        {{ $t("ongoing_interviews") }}
      </div>
      <div class="d-flex flex-row align-center">
        <div class="whirelab-conclud-folder">
          <v-badge
            :content="interviews.completed.length"
            :value="interviews.completed.length"
            bordered
            color="primary"
            overlap
          >
            <v-btn icon @click="go()">
              <v-icon large>mdi-folder-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <span class="whirelab-conclud-tooltip">{{
            $t("view_concluded_canceled_interviews")
          }}</span>
        </div>
        <div class="text-right px-0 ml-6">
          <form v-on:submit.prevent="search()">
            <v-text-field
              style="text-decoration: captalize"
              v-model="text"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
              @click:append="search()"
              @input="search()"
            ></v-text-field>
          </form>
        </div>
      </div>
    </v-row>

    <v-row>
      <v-expansion-panels>
        <template v-for="interview in interviews.outstanding">
          <expansion-panel
            :interviewId="$route.params.id"
            :interviews="interview"
            :key="interview.timestemp"
          />
        </template>
      </v-expansion-panels>
    </v-row>

    <add-recruter-dialog />
    <add-observer-dialog />
    <re-schedule-interview />
    <finish-interview />
    <cancel-interview />
    <edit-email-dialog />
    <success-dialog />
    <options-re-schedule />

    <warning-dialog
      :dialog="dialogWarning"
      :message="$t('IntervieWorning')"
      @close="dialogWarning = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_INTERVIEW_BY_ID_QUERY } from "../graphql/Query.gql";
import { UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION } from "../graphql/Subscraption.gql";
import { groupBy } from "@/utils";
import ExpansionPanel from "../components/panel/ExpansionPanel.vue";
import AddRecruterDialog from "../components/AddRecruterDialog.vue";
import AddObserverDialog from "../components/AddObserverDialog.vue";
import ReScheduleInterview from "../components/ReScheduleInterview.vue";
import FinishInterview from "../components/FinishInterview.vue";
import CancelInterview from "../components/CancelInterview.vue";
import EditEmailDialog from "../components/EditEmailDialog.vue";
import SuccessDialog from "../components/SuccessDialog.vue";
import OptionsReSchedule from "../components/OptionsReSchedule";

import WarningDialog from "./../../../components/WarningDialog.vue";

export default {
  name: "ScheduledInterview",

  components: {
    ExpansionPanel,
    AddRecruterDialog,
    AddObserverDialog,
    ReScheduleInterview,
    FinishInterview,
    CancelInterview,
    EditEmailDialog,
    SuccessDialog,
    OptionsReSchedule,
    WarningDialog,
  },

  data: () => ({
    interview: {},
    text: "",
    alert: true,
    dialogWarning: false,
    joined: false,
  }),

  apollo: {
    interview: {
      query: GET_INTERVIEW_BY_ID_QUERY,
      variables() {
        return { id: this.$route.params.id };
      },
      fetchPolicy: "cache-and-network",
    },
    $subscribe: {
      updateInterviewSchedule: {
        query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          this.interview = data.updateInterviewSchedule;
        },
      },
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("joined", () => {
      this.joined = true;
    });
    // eslint-disable-next-line no-undef
    Fire.$on("left", () => {
      this.joined = false;
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.joined) {
      // eslint-disable-next-line no-undef
      this.dialogWarning = true;
      next(false);
    } else {
      next(true);
    }
  },

  computed: {
    ...mapGetters({
      getBlockedCandidates: "candidate/getBlockedCandidates",
    }),
    interviews() {
      const ZERO_NUMBER = 0;
      const blockeds = [];
      const candidates = this.interview.candidates;
      let list = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            const stage = findCandidate.stages.find(
              (findStage) => findStage.name === findCandidate.currentStage
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              message: this.interview.message,
              vacancy: this.interview.vacancy.title,
              timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
              blocked: findIndex >= ZERO_NUMBER,
              sort: this.status(findCandidate),
            };
          })
        : [];

      const completed = list.filter(
        (filterCandidate) =>
          filterCandidate.status === "rejected" ||
          filterCandidate.status === "finished" ||
          filterCandidate.status === "canceled"
      );

      list = list.filter(
        (filterCandidate) =>
          filterCandidate.status !== "approved" &&
          filterCandidate.status !== "rejected" &&
          filterCandidate.status !== "finished" &&
          filterCandidate.status !== "canceled"
      );

      list.sort((a, b) => a.sort - b.sort);

      const outstanding = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      let resultItems = { outstanding, completed };

      // if (this.textSearch) {
      //   const text = this.replaceSpecialChars(this.textSearch)
      //   list = list.filter(c =>
      //     this.replaceSpecialChars(resultItems[`${c.date} ${c.time}`][0].candidate.fullName).includes(text)
      //   )
      // }

      resultItems.outstanding = groupBy(
        list,
        "timestemp",
        (record, dateKey) => {
          return [record[dateKey]];
        }
      );

      return resultItems;
    },
  },

  methods: {
    go() {
      this.$router.push({
        name: "completed.interviews",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goBack() {
      this.$router.push({
        name: "interviews.preview",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    status(candidate) {
      let states = [];
      candidate.stages.forEach((mapStage) => states.push(mapStage.state));

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        ongoing = 0;

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          default:
            ongoing += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return 1;
      } else if (TOTAL_STATUS === ongoing) {
        return 2;
      }

      return 1;
    },
  },
};
</script>

<style scoped>
.whirelab-conclud-folder {
  position: relative;
  display: inline-block;
}

.whirelab-conclud-folder .whirelab-conclud-tooltip {
  visibility: hidden;
  width: 280px;
  background-color: #757575;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  padding: 5px 5px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  margin-left: -120px;
}

.whirelab-conclud-folder:hover .whirelab-conclud-tooltip {
  visibility: visible;
}
</style>