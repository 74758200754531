<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card class="px-10 pt-12 pb-10">
      <v-radio-group v-model="selectedStage">
        <v-row class="d-flex flex-column lign-center mb-5">
          <div
            class="text-left pa-5"
            :class="{ 'option-active': selectedStage === currentStage.name }"
          >
            <v-radio :value="currentStage.name">
              <template v-slot:label>
                <div
                  class="text-left"
                  v-html="
                    $t('options_re_sachedule_text_1', {
                      stagePosition: currentStage.index,
                      stageName: currentStage.name,
                    })
                  "
                >
                  <!-- Agendar e <span class="primary--text">continuar</span> na
                  {{ currentStage.index }}º fase de [{{ currentStage.name }}] -->
                </div>
              </template>
            </v-radio>
            <!-- Agendar e continuar na 1* fase de [dinâmica de grupo] -->
          </div>
          <div
            v-if="nextStage.index"
            class="text-left pa-5"
            :class="{ 'option-active': selectedStage === nextStage.name }"
          >
            <v-radio :value="nextStage.name">
              <template v-slot:label>
                <div
                  class="text-left"
                  v-html="
                    $t('options_re_sachedule_text_2', {
                      stagePosition: nextStage.index,
                      stageName: nextStage.name,
                      previewStage: currentStage.index,
                    })
                  "
                >
                  <!-- Agendar a {{ nextStage.index }}º fase [{{ nextStage.name }}] e
                  <span class="primary--text">terminar</span> a
                  {{ currentStage.index }}º fase. -->
                </div>
              </template>
            </v-radio>
            <!-- Agendar e continuar na 1* fase de [dinâmica de grupo] -->
          </div>
        </v-row>
      </v-radio-group>
      <v-row class="justify-end px-10">
        <v-btn text samll color="primary" @click="dialog = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn small color="primary" @click="schedule()">
          {{ $t("continue") }}
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OptionsReSchedule",

  data: () => ({
    dialog: false,
    isFirstOption: true,
    selectedStage: "",
    currentStage: {
      name: "",
      index: null,
    },
    nextStage: {
      name: "",
      index: null,
    },
    interviews: [],
    interviewId: null,
    isCurrentStage: false,
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on(
      "OptionsReSchedule",
      ({ selectedCandidate, interviews, interviewId }) => {
        const CURRENT_STAGE_NAME = selectedCandidate.currentStage;
        const currentStageIndex = selectedCandidate.stages.findIndex(
          (findState) => findState.name === CURRENT_STAGE_NAME
        );

        this.currentStage.name = CURRENT_STAGE_NAME;
        this.currentStage.index = currentStageIndex + 1;

        this.selectedStage = this.currentStage.name;

        const NEXT_STAGE = selectedCandidate.stages[this.currentStage.index];

        if (NEXT_STAGE) {
          this.nextStage.name = NEXT_STAGE.name;
          this.nextStage.index = this.currentStage.index + 1;
        } else {
          this.nextStage.name = "";
          this.nextStage.index = null;
        }

        this.interviews = interviews;

        this.interviewId = interviewId;

        this.dialog = true;
      }
    );
  },

  methods: {
    schedule() {
      const params = {
        interviews: this.interviews,
        stage: this.selectedStage,
        interviewId: this.interviewId,
        isCurrentStage: this.selectedStage === this.currentStage.name,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("ReScheduleInterview", params);
      this.dialog = false;
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },
  },
};
</script>

<style scoped>
.option-active {
  background: #f7a40030;
}
</style>